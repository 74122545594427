import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paperSize: {
    width: '600px',
  },
  downloadButton: {
    marginTop: '10px',
    padding: '10px',
  },
}));
