export enum DECAL_IMPORT_STATUS {
  NEW = 'New',
  INPROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  FAILED = 'Failed',
}

export enum IMPORT_CUSTOMS_DECAL_FILTER {
  NAME = 'File Name',
  STATUS = 'Status',
}
