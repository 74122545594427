export const fields = {
  serialNumber: {
    label: 'Serial Number',
  },
  cappsId: {
    label: 'Capps',
  },
  maxTakeOffWeight: {
    label: 'Max Take Off Weight',
  },
};
